<template>
  <div style="font-style: italic;font-family: Helvetica;color: rgba( 164, 164, 164, 1);font-size: 9pt;letter-spacing: 1pt;">
    <slot></slot>
    <el-tooltip class="item" effect="dark" :content="info" placement="top-start" v-if="info">
      <i class="el-icon-info"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      default: false
    }
  }
}
</script>
